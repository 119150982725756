










import {Component, Prop, Vue, Watch} from 'vue-property-decorator'

@Component({
  name: 'SliderImage'
})
export default class SliderImage extends Vue {
  @Prop() public alt?: string;
  @Prop() public dataSrc!: string;
  @Prop() public show!: boolean;

  private src = this.placeholderSrc;
  private loaded = false;

  get placeholderSrc(): string {
    return 'data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 "%3E%3C/svg%3E'
  }

  @Watch('show')
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onShowChanged(val: boolean, oldVal: boolean) {
    if (val && !this.loaded) {
      this.src = this.dataSrc;
      this.loaded = true;
    }
  }
}

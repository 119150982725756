import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueScrollTo from 'vue-scrollto'
import {RemoteDataMapper} from 'data-mapper-core'

Vue.use(VueScrollTo, {
  container: 'body',
  duration: 500,
  easing: 'linear',
  offset: -50,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true
});

const baseURL = process.env.VUE_APP_BASE_URL;
RemoteDataMapper.baseURL = baseURL;

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');











import ContactForm from '@/components/ContactForm.vue'
import {Component, Vue} from 'vue-property-decorator'

@Component({
  components: {
    ContactForm
  }
})
export default class Contact extends Vue {

}













































import {Component, Vue, Watch} from 'vue-property-decorator'
import SiteFooter from '@/components/Footer.vue'

@Component({
  components: {
    SiteFooter
  }
})
export default class App extends Vue {
  show = false;

  public showNav(): void {
    this.show = !this.show;
  }

  @Watch('$route')
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  nameChanged(to: any, from: any) {
    this.show = false;
  }
}

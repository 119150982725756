import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/notes',
    name: 'Notes',
    component: () => import('../views/Notes.vue')
  },
  {
    path: '/blog',
    name: 'Blog',
    component: () => import('../views/Blog.vue')
  },
  {
    path: '/blog/transaction-iso',
    name: 'TransactionIso',
    component: () => import('../views/blog/TransactionIso.vue')
  },
  {
    path: '/blog/python-data-structures',
    name: 'PythonDataStructures',
    component: () => import('../views/blog/PythonDataStructures.vue')
  },
  {
    path: '/blog/async-python',
    name: 'AsyncPython',
    component: () => import('../views/blog/AsyncPython.vue')
  },
  {
    path: '/blog/cpython-types',
    name: 'CpythonTypes',
    component: () => import('../views/blog/CpythonTypes.vue')
  },
  {
    path: '/blog/coro-evolution',
    name: 'CoroEvolution',
    component: () => import('../views/blog/CoroEvolution.vue')
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

export default router







































import {Component, Prop, Vue} from 'vue-property-decorator'
import {ImageData, SliderOptions} from '@/code/slider-options'
import SliderImage from '@/components/slider/SliderImage.vue'

@Component({
  name: 'Slider',
  components: {
    SliderImage
  }
})
export default class Slider extends Vue {
  @Prop() private options!: SliderOptions;

  currentIndex = -1;

  mounted() {
    this.currentIndex = 0;
  }

  get imageList(): Array<ImageData> {
    return this.options.imageList
  }

  get imageCount(): number {
    return this.options.imageList.length
  }

  public showImage(index: number): boolean {
    return index === this.currentIndex
  }

  public showDescription(item: ImageData, index: number): boolean {
    const hasTitle = item.title != null && item.title.length > 0;
    const hasDescription = item.description != null && item.description.length > 0;
    return this.showImage(index) && (hasTitle || hasDescription)
  }

  public prev(): void {
    const i: number = this.currentIndex - 1;
    if (i >= 0) {
      this.currentIndex = i;
    } else {
      this.currentIndex = this.imageCount - 1;
    }
  }

  public next(): void {
    const i: number = this.currentIndex + 1;

    if (i < this.imageCount) {
      this.currentIndex = i;
    } else {
      this.currentIndex = 0;
    }
  }
}


















































import {Component, Vue} from 'vue-property-decorator'

@Component
export default class Footer extends Vue {
  public get year(): number {
    return new Date().getFullYear();
  }
}

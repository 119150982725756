













import {Component, Vue, Watch} from 'vue-property-decorator'
import Skills from '@/components/home/Skills.vue'
import Experience from '@/components/home/Experience.vue'
// import Portfolio from '@/components/home/Portfolio.vue'
import Education from '@/components/home/Education.vue'
import Certification from '@/components/home/Certification.vue'
import About from '@/components/home/About.vue'
import Contact from '@/components/home/Contact.vue'
import VueScrollTo from 'vue-scrollto'
import Slider from '@/components/slider/Slider.vue'
import {SliderOptions} from '@/code/slider-options'

@Component({
  components: {
    Slider,
    Skills,
    Experience,
    // Portfolio,
    Education,
    Certification,
    About,
    Contact
  }
})
export default class Home extends Vue {
  @Watch('$route')
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  nameChanged(to: any, from: any) {
    if (to.hash) {
      VueScrollTo.scrollTo(to.hash)
    }
  }

  mounted() {
    if (this.$route.hash) {
      setTimeout(() => {
        VueScrollTo.scrollTo(this.$route.hash)
      })
    }
  }

  public sliderOption: SliderOptions = {
    imageList: [
      {
        src: require('@/assets/slider/1.jpg'),
        alt: 'Гориненко Антон'
      },
      {
        src: require('@/assets/slider/7.jpg'),
        alt: 'Гориненко Антон'
      },
      {
        src: require('@/assets/slider/6.jpg'),
        alt: 'Гориненко Антон'
      }
    ]
  };
}
